.main {
  flex-grow: 1;
  max-width: 1140px;
  margin: auto;
  width: 100%;
}

.page {
  margin: auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
