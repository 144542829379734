.header {
  max-width: 1100px;
  width: calc(100% - 40px);
  display: flex;
  padding: 50px 20px;
  justify-content: space-between;
  margin: auto;
  align-items: center;
}

@media (max-width: 500px) {
  .header {
    display: block;
  }
}

.header nav {
  display: flex;
  max-height: 70px;
  white-space: nowrap;
  margin: -15px;
}

.header h1 a {
  color: #333;
  text-decoration: none;
}

.header h1 a {
  font-size: 45px;
  font-weight: bold;
  white-space: nowrap;
}

.header nav a {
  text-decoration: none;
  font-size: 22px;
  margin: 15px;
  position: relative;
  color: #333;
  display: flex;
  align-items: center;
}

.header nav a p {
  padding: 10px 0;
  margin: 0;
}

.header nav a p:after {
  background-color: #333;
  width: 100%;
  height: 2px;
  left: 0;
  bottom: 0px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transform: translateY(4px);
  transition: all 0.15s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;
  content: '';
}

.header nav a:hover p:after,
.header nav a.active p:after {
  opacity: 1;
  transform: translateY(0px) translateZ(0);
}
