.main {
  padding: 0 20px 100px 20px;
}

.lede {
  margin-bottom: 50px;
  font-size: 20px;
  line-height: 2em;
  max-width: 800px;
}

.gridContainer {
  display: flex;
  margin: -15px;
}

.gridContainer > * {
  margin: 15px;
}
