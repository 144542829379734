@import url(https://fonts.googleapis.com/css2?family=Lato&family=Raleway:wght@500&display=swap);
html,
body {
  padding: 0;
  margin: 0;
  font-family: Lato, sans-serif;
  color: #333;
  line-height: 1.5em;
}

h1,
h2,
h3 {
  font-family: Raleway, sans-serif;
}

a {
  color: #0047ec;
}

.About_wrapper__1v4Ib {
  display: flex;
  max-width: 1140px;
  padding: 0 20px 100px 20px;
  grid-column-gap: 80px;
  -webkit-column-gap: 80px;
          column-gap: 80px;
  grid-row-gap: 30px;
  row-gap: 30px;
  justify-content: center;
}

.About_wrapper__1v4Ib strong {
  font-size: 21px;
  margin-bottom: 30px;
  display: block;
}

.About_photo__khhsK {
  width: 100%;
  position: absolute;
}

.About_side__wzCt3 {
  max-width: 400px;
  min-width: 300px;
}

.About_grow__ARk29 {
  flex-grow: 1;
}

@media (max-width: 1000px) {
  .About_photo__khhsK {
    max-width: 300px;
  }
}

@media (max-width: 800px) {
  .About_wrapper__1v4Ib {
    flex-wrap: wrap;
    align-items: center;
  }
  .About_grow__ARk29 {
    display: none;
  }
}

.About_imageWrapper__3Ooy9 {
  max-width: 500px;
  max-height: 75vh;
  position: relative;
  width: 100%;
}

.About_imageWrapper__3Ooy9 div {
  padding-bottom: 150%;
  background: rgba(0, 0, 0, 0.05);
}

.Projects_main__22txu {
  padding: 0 20px 100px 20px;
}

.Projects_lede__2H3oV {
  margin-bottom: 50px;
  font-size: 20px;
  line-height: 2em;
  max-width: 800px;
}

.Projects_gridContainer__EXhhZ {
  display: flex;
  margin: -15px;
}

.Projects_gridContainer__EXhhZ > * {
  margin: 15px;
}

.ProjectTile_wrapper__3Ug_D {
  display: block;
  flex-basis: 200px;
  flex-grow: 1;
  max-width: 350px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.05);
}

.ProjectTile_wrapper__3Ug_D img {
  width: 100%;
  -webkit-filter: brightness(1.15);
          filter: brightness(1.15);
  position: absolute;
}

.ProjectTile_overlay__2q89D {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: currentColor;
  opacity: 0.05;
  transition: all 0.3s ease;
}

.ProjectTile_wrapper__3Ug_D:hover .ProjectTile_overlay__2q89D {
  opacity: 0.65;
}

.ProjectTile_wrapper__3Ug_D .ProjectTile_aspectRatio__1HDCz {
  width: 100%;
  padding-bottom: 200%;
}

.Layout_main__auk_r {
  flex-grow: 1;
  max-width: 1140px;
  margin: auto;
  width: 100%;
}

.Layout_page__Zxb0U {
  margin: auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.Header_header__2otSh {
  max-width: 1100px;
  width: calc(100% - 40px);
  display: flex;
  padding: 50px 20px;
  justify-content: space-between;
  margin: auto;
  align-items: center;
}

@media (max-width: 500px) {
  .Header_header__2otSh {
    display: block;
  }
}

.Header_header__2otSh nav {
  display: flex;
  max-height: 70px;
  white-space: nowrap;
  margin: -15px;
}

.Header_header__2otSh h1 a {
  color: #333;
  text-decoration: none;
}

.Header_header__2otSh h1 a {
  font-size: 45px;
  font-weight: bold;
  white-space: nowrap;
}

.Header_header__2otSh nav a {
  text-decoration: none;
  font-size: 22px;
  margin: 15px;
  position: relative;
  color: #333;
  display: flex;
  align-items: center;
}

.Header_header__2otSh nav a p {
  padding: 10px 0;
  margin: 0;
}

.Header_header__2otSh nav a p:after {
  background-color: #333;
  width: 100%;
  height: 2px;
  left: 0;
  bottom: 0px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
  transition: all 0.15s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;
  content: '';
}

.Header_header__2otSh nav a:hover p:after,
.Header_header__2otSh nav a.Header_active__WHMYj p:after {
  opacity: 1;
  -webkit-transform: translateY(0px) translateZ(0);
          transform: translateY(0px) translateZ(0);
}

.Footer_footer__28pE7 {
  display: flex;
  background-color: #f0f0f0;
  text-align: left;
  padding: 30px 10px 50px 10px;
}

.Footer_footer__28pE7 a:hover {
  color: #0047ec;
}

.Footer_footer__28pE7 a {
  padding: 20px;
  color: #333;
  text-decoration: none;
}

.Footer_inner__19m4g {
  max-width: 1100px;
  margin: auto;
  display: flex;
}

.MonsterHunt_wrapper__e4aUH {
  padding: 0 20px 100px 20px;
}

.MonsterHunt_iframe__2b4r8 {
  border: none;
  margin: auto;
  display: block;
  margin-bottom: 30px;
  background: #f0f0f0;
}

.MonsterHunt_table__1klOA {
  display: table;
  margin-left: -10px;
}

.MonsterHunt_divider__1NG1L {
  height: 1px;
  width: 100%;
  background: #bbb;
  margin: 50px 0;
}

.MonsterHunt_table__1klOA div {
  display: table-row;
}

.MonsterHunt_table__1klOA p {
  display: table-cell;
  padding: 5px 10px;
}

.MonsterHunt_table__1klOA p:first-child {
  font-style: italic;
}

.MonsterHunt_wrapper__e4aUH img {
  max-width: 100%;
}

.MonsterHunt_playMe__1n8ot {
  text-align: center;
  margin-bottom: 50px;
}

.MonsterHunt_playMe__1n8ot p {
  border-radius: 100px;
  padding: 10px 20px;
  background: #f0f0f0;
  display: inline-block;
  font-weight: 800;
  text-transform: uppercase;
  color: #555;
  font-size: 14px;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.05);
  -webkit-animation: MonsterHunt_pulse__3k_7E 3s infinite;
          animation: MonsterHunt_pulse__3k_7E 3s infinite;
}

@-webkit-keyframes MonsterHunt_pulse__3k_7E {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.05);
  }
  70% {
    box-shadow: 0 0 0 20px rgba(0, 0, 44, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes MonsterHunt_pulse__3k_7E {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.05);
  }
  70% {
    box-shadow: 0 0 0 20px rgba(0, 0, 44, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.MonsterHunt_body__2c0CS {
  max-width: 600px;
  margin: 50px auto;
}

.Dynastia_wrapper__3QPCB {
  padding: 0 20px 100px 20px;
}

.Dynastia_iframe__1-ZCm {
  border: none;
  margin: auto;
  display: block;
  margin-bottom: 30px;
  background: #f0f0f0;
}

.Dynastia_table__2AGkk {
  display: table;
  margin-left: -10px;
}

.Dynastia_divider__1zXPB {
  height: 1px;
  width: 100%;
  background: #bbb;
  margin: 50px 0;
}

.Dynastia_table__2AGkk div {
  display: table-row;
}

.Dynastia_table__2AGkk p {
  display: table-cell;
  padding: 5px 10px;
}

.Dynastia_table__2AGkk p:first-child {
  font-style: italic;
}

.Dynastia_wrapper__3QPCB img {
  max-width: 100%;
}

.Dynastia_playMe__1JcRb {
  text-align: center;
  margin-bottom: 50px;
}

.Dynastia_playMe__1JcRb button {
  border: none;
  border-radius: 100px;
  padding: 10px 20px;
  background: #f0f0f0;
  display: inline-block;
  font-weight: 800;
  text-transform: uppercase;
  color: #555;
  font-size: 14px;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.05);
  -webkit-animation: Dynastia_pulse__KmHlp 3s infinite;
          animation: Dynastia_pulse__KmHlp 3s infinite;
}

@-webkit-keyframes Dynastia_pulse__KmHlp {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.05);
  }
  70% {
    box-shadow: 0 0 0 20px rgba(0, 0, 44, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes Dynastia_pulse__KmHlp {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.05);
  }
  70% {
    box-shadow: 0 0 0 20px rgba(0, 0, 44, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.Dynastia_body__13aoG {
  max-width: 600px;
  margin: 50px auto;
}

.Error_error__2SNWX {
  text-align: center;
  margin: 50px;
}








