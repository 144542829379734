.wrapper {
  display: block;
  flex-basis: 200px;
  flex-grow: 1;
  max-width: 350px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.05);
}

.wrapper img {
  width: 100%;
  filter: brightness(1.15);
  position: absolute;
}

.overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: currentColor;
  opacity: 0.05;
  transition: all 0.3s ease;
}

.wrapper:hover .overlay {
  opacity: 0.65;
}

.wrapper .aspectRatio {
  width: 100%;
  padding-bottom: 200%;
}
