.wrapper {
  display: flex;
  max-width: 1140px;
  padding: 0 20px 100px 20px;
  column-gap: 80px;
  row-gap: 30px;
  justify-content: center;
}

.wrapper strong {
  font-size: 21px;
  margin-bottom: 30px;
  display: block;
}

.photo {
  width: 100%;
  position: absolute;
}

.side {
  max-width: 400px;
  min-width: 300px;
}

.grow {
  flex-grow: 1;
}

@media (max-width: 1000px) {
  .photo {
    max-width: 300px;
  }
}

@media (max-width: 800px) {
  .wrapper {
    flex-wrap: wrap;
    align-items: center;
  }
  .grow {
    display: none;
  }
}

.imageWrapper {
  max-width: 500px;
  max-height: 75vh;
  position: relative;
  width: 100%;
}

.imageWrapper div {
  padding-bottom: 150%;
  background: rgba(0, 0, 0, 0.05);
}
