.wrapper {
  padding: 0 20px 100px 20px;
}

.iframe {
  border: none;
  margin: auto;
  display: block;
  margin-bottom: 30px;
  background: #f0f0f0;
}

.table {
  display: table;
  margin-left: -10px;
}

.divider {
  height: 1px;
  width: 100%;
  background: #bbb;
  margin: 50px 0;
}

.table div {
  display: table-row;
}

.table p {
  display: table-cell;
  padding: 5px 10px;
}

.table p:first-child {
  font-style: italic;
}

.wrapper img {
  max-width: 100%;
}

.playMe {
  text-align: center;
  margin-bottom: 50px;
}

.playMe button {
  border: none;
  border-radius: 100px;
  padding: 10px 20px;
  background: #f0f0f0;
  display: inline-block;
  font-weight: 800;
  text-transform: uppercase;
  color: #555;
  font-size: 14px;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.05);
  animation: pulse 3s infinite;
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.05);
  }
  70% {
    -moz-box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 20px rgba(0, 0, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.body {
  max-width: 600px;
  margin: 50px auto;
}
