.error {
  text-align: center;
  margin: 50px;
}







