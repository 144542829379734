.footer {
  display: flex;
  background-color: #f0f0f0;
  text-align: left;
  padding: 30px 10px 50px 10px;
}

.footer a:hover {
  color: #0047ec;
}

.footer a {
  padding: 20px;
  color: #333;
  text-decoration: none;
}

.inner {
  max-width: 1100px;
  margin: auto;
  display: flex;
}
